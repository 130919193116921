import React, { useEffect, createRef } from "react"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { useStaticQuery, graphql, Link } from "gatsby"
import GofloaterSearchForm from "../components/search/searchalter"
import "../styles/hybrid.scss"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/TeamLogo.svg"
import lottie from "lottie-web"
import SafeSpace from "../img/safety-badge.jpg"
import animation from "../animations/newhomeanimation.json"
import "../styles/coworking.scss"
export default function OfficeSpace() {
  const data = useStaticQuery(graphql`
    query OfficePageQuery {
      allCities {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
    }
  `)
  const cities = data

  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [animationContainer])
  return (
    <div>
      <SEOHeader
        title="Explore best shared office space across India - GoFloaters"
        description="Explore shared office spaces on a monthly rental basis across India. Search and get access to affordable desks and private cabins in fully equipped shared office spaces."
        socialURL="https://assets.gofloaters.com/socialimage/office-spaces.jpg"
        pinterest="true"
      ></SEOHeader>

      <LayoutTeam>
        <div className="coworkingSpaces">
          <div>
            <Navigation />
            <section className="banner">
              <div className="container">
                <div className="row aligner">
                  <div className="col-md-5 coworkingSpacesSearch">
                    <h1>Find a Office Space</h1>
                    <p className="subHeading">
                      Office for every size | All inclusive pricing
                    </p>
                    <GofloaterSearchForm
                      placeholder="Search by location"
                      spacetype="monthlyofficeSpace"
                    />
                    <br></br>
                    <br></br>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4 col-6">
                          <h2>40+</h2>
                          <p className="subText">Cities we operate in</p>
                        </div>
                        <div className="col-md-4 col-6">
                          <h2>3000+</h2>
                          <p className="subText">Curated Workspaces</p>
                        </div>
                        <div className="col-md-4 col-6">
                          <h2>30,000+</h2>
                          <p className="subText">Customers booking monthly</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <img
                      src="https://assets.gofloaters.com/coworking/office-space.png"
                      className="img-responsive"
                      alt="Explore best shared office space across India - GoFloaters"
                    ></img>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="padding60">
          <div className="container">
            <div className="row">
              {cities.allCities.edges.map(city => {
                return (
                  <div className="col-md-2 col-4 city-icons">
                    <Link to={"/office-spaces/" + city.node.slug + "/"}>
                      <img
                        src={
                          "https://assets.gofloaters.com/coworking/" +
                          city.node.slug +
                          ".svg"
                        }
                        alt={city.node.displayCity}
                      ></img>
                      <br></br>
                      {city.node.displayCity}
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  Shared Office Amenities
                </h2>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/hi_speed_wifi.png"
                        alt="High Speed WiFi"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>High Speed WiFi</h3>
                      <p>
                        High-Speed WiFi to get you going the moment you enter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/power_backup.png"
                        alt="Power Backup"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Power Backup</h3>
                      <p>
                        Power backup to ensure that you are able to work
                        uninterrupted.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/ac.png"
                        alt="AC"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>AC</h3>
                      <p>
                        Climate controlled environment with cooling set
                        appropriately to make your work pleasurable
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/free_parking.png"
                        alt="Parking"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Parking</h3>
                      <p>Free parking inside and near the space.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/coffee_tea.png"
                        alt="Coffee / Tea"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Tea & Coffee</h3>
                      <p>
                        Recharge yourself with a coffee or your favorite
                        beverage when needed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="container">
                  <div className="row iconCoworking aligner">
                    <div className="col-md-3 col-3">
                      <img
                        src="https://assets.gofloaters.com/icons/printer.png"
                        alt="printer"
                        className="img-responsive"
                      ></img>
                    </div>
                    <div className="col-md-9 col-9">
                      <h3>Printer</h3>
                      <p>
                        Printing and scanning facilities available on request.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                {" "}
                <br></br>
                <p style={{ fontSize: "12px" }}>
                  * Check the space details page for each space to explore the
                  amenities available at that space
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                  }}
                >
                  The right office space solution for
                </h2>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/remote-team.jpg"
                  alt="Remote Teams"
                ></img>
                <h4>Remote Teams</h4>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/startups.jpg"
                  alt="Startups"
                ></img>
                <h4>Startups</h4>
              </div>
              <div className="col-md-4 CoworkingimageBox">
                <img
                  src="https://assets.gofloaters.com/coworking/freelancer.jpg"
                  alt="Freelancers"
                ></img>
                <h4>Freelancers</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 style={{ fontWeight: "bold" }}>
                Shared Office Space for Rent
              </h4>
              <p>
                GoFloaters provides workspaces under the coworking space and
                office space (also called shared office space) categories.
                Shared office spaces on GoFloaters are available on a monthly
                rental model. Whether you are in Chennai, Bengaluru, Delhi,
                Gurgaon, Noida, Pune, Mumbai, Hyderabad, Kochi, Coimbatore,
                Trichy or Salem you can find the best workspaces that fit within
                your budget. We are adding more cities every month. To search
                for “shared workspace near me” you can just put in your location
                or locality in the search bar and you will get the choicest of
                options from GoFloaters.
              </p>
              <br />
              <h4 style={{ fontWeight: "bold" }}>
                What are the benefits of shared office spaces and coworking
                spaces?
              </h4>
              <p>
                Traditional offices burden a business with high rents, hidden
                charges and high lock in periods. These have given way for
                stylish, contemporary and functional shared office spaces and
                coworking spaces. Whether you are a freelancer, startup or a
                service company your choice of workspace in 2022 should be
                coworking spaces or shared office spaces. Here are the top
                benefits that you get when you choose either of the two :
              </p>
              <br />
              <h4 style={{ fontWeight: "bold" }}>
                What are the benefits of shared office spaces and coworking
                spaces?
              </h4>

              <p>
                Traditional offices burden a business with high rents, hidden
                charges and high lock in periods. These have given way for
                stylish, contemporary and functional shared office spaces and
                coworking spaces. Whether you are a freelancer, startup or a
                service company your choice of workspace in 2022 should be
                coworking spaces or shared office spaces. Here are the top
                benefits that you get when you choose either of the two :&nbsp;
              </p>
              <br />
              <ul>
                <li>
                  <strong>Flexibility</strong> : No long term contracts that
                  block you{" "}
                </li>
                <li>
                  <strong>Cost Effective</strong> : You pay one simple and
                  affordable rent for the space and all the amenities{" "}
                </li>
                <li>
                  <strong>Pay-per-use</strong> : This is something unique to
                  GoFloaters where we have made the choicest of spaces available
                  on daily and hourly rentals.&nbsp;
                </li>
                <li>
                  <strong>Location choice</strong> : With thousands of options
                  available you can get a work space where you need it{" "}
                </li>
                <li>
                  <strong>Community</strong> : Coworking spaces are the breeding
                  ground for strong communities of entrepreneurs and freelancers{" "}
                </li>
              </ul>
              <br />
              <h4 style={{ fontWeight: "bold" }}>
                How to Find The Best Shared Office Space for Rent in India
              </h4>
              <p>
                Looking to embark on your journey to find the perfect shared
                office space in India? With a dynamic business landscape and a
                surge in remote work, finding the right workspace has become
                increasingly important. Whether you're a budding entrepreneur, a
                freelancer, or a growing startup, the right office space can
                significantly impact your productivity and success.
                <br />
                <br />
                So, where do you begin your quest for the ideal{" "}
                <a href="https://gofloaters.com/office-spaces/bengaluru/">
                  shared office space
                </a>
                ? Here are some valuable tips to guide you along the way:
                <br />
                <br />
                <strong>1. Define Your Needs:</strong> First things first,
                understand what you require in a workspace. Consider factors
                like location, amenities, budget, and the type of environment
                that suits your work style. Are you looking for a quiet, private
                office or a collaborative, open-plan setting? Clarifying your
                needs is the foundation of your search. <br /> <br />
                <strong>2. Explore Online Platforms:</strong> In the digital
                age, searching for office spaces has never been easier. Online
                platforms like GoFloaters have emerged as a game-changer. At
                GoFloaters, we offer a vast array of shared office spaces for
                rent across India, making it a one-stop destination for all your
                workspace needs. You can effortlessly{" "}
                <a href="https://gofloaters.com/office-spaces/chennai/">
                  browse through diverse options
                </a>
                , compare prices, and even book spaces with just a few clicks.
                <br /> <br />
                <strong>3. Location Matters:</strong> The location of your
                shared office space can impact your convenience and
                accessibility. Consider proximity to transportation hubs,
                clients, and other essential services. With GoFloaters, you can
                easily filter your search by location, ensuring you{" "}
                <a href="https://gofloaters.com/office-spaces/mumbai/">
                  find a workspace
                </a>{" "}
                that suits your preferences.
                <br /> <br />
                <strong>4. Amenities Galore: </strong>Shared office spaces come
                with a range of amenities, from high-speed internet and{" "}
                <a href="https://gofloaters.com/meeting-spaces/">
                  fully-equipped meeting rooms
                </a>{" "}
                to free coffee and printing services. Determine which amenities
                are non-negotiable for your work routine and select a space that
                checks all the boxes.
                <br /> <br />
                <strong>5. Flexibility is Key:</strong> In today's fast-paced
                business world, flexibility is invaluable. Many shared office
                providers offer flexible rental terms, allowing you to choose
                between short-term and long-term leases. This adaptability can
                be a lifesaver for entrepreneurs and businesses with evolving
                needs. <br />
                Looking for temporary office spaces for rent?{" "}
                <a href="https://tempoffice.gofloaters.com/">Book here</a>
                <br /> <br />
                <strong>6. Reviews and Recommendations:</strong> Don't
                underestimate the power of reviews and recommendations. Seek
                insights from fellow entrepreneurs and professionals who have
                used shared office spaces. Their experiences can provide
                valuable insights into the quality and atmosphere of a
                particular workspace. <br /> <br />
                <strong>7. Visit Before Committing:</strong> Before finalizing
                your choice, schedule a visit to the shortlisted shared office
                spaces. This gives you a firsthand feel of the environment and
                helps you ensure it aligns with your expectations. We recommend
                this approach even if you&rsquo;d like to book a virtual office
                in India.{" "}
                <a href="https://virtualoffice.gofloaters.com/">Check here</a>.
                <br />
                <br />
                Whether you're in Hyderabad, Kolkata, or any other city across
                the country, your dream workspace is just a click away. So, get
                started on your journey to a productive and inspiring work
                environment today!
                <br />
                <br />
              </p>
              <h4 style={{ fontWeight: "bold" }}>
                Why should I use the GoFloaters app to book?
              </h4>
              <p>
                GoFloaters has been in the space of helping individuals and
                teams find flexible and affordable workspaces for over 3 years
                now. GoFloaters was started with a vision to help anyone get an
                office space when they want, where they want and within their
                budget. We set out to build office spaces for a distributed
                world where individuals and teams should be able to work near
                home.&nbsp;
              </p>
              <br />
              <p>
                Following are the advantages of booking coworking spaces and
                meeting spaces on the GoFloaters app:
              </p>
              <ul>
                <li>
                  <strong>Work Near Home </strong>: In most of the cities that
                  we serve you can get a space within 5 kms of your home or
                  wherever you are{" "}
                </li>
                <li>
                  <strong>Pay-per-use</strong> : You can book coworking spaces
                  on a daily basis and meeting spaces on an hourly basis and pay
                  only for the time you have used it.{" "}
                </li>
                <li>
                  <strong>No contracts </strong>: You don&rsquo;t have to sign
                  any kind of contracts with us.&nbsp;
                </li>
                <li>
                  <strong>Cost effective</strong> : We have negotiated heavily
                  with our space partners to bring you savings of at least 20%
                  if not more on the day pass rates and the meeting room rents.{" "}
                </li>
                <li>
                  <strong>Instant Bookings</strong> : No need to call anyone or
                  email anyone to check space availability. You can book
                  coworking spaces instantaneously. Meeting spaces just need 30
                  mins of time for confirmation.&nbsp;&nbsp;
                </li>
                <li>
                  <strong>Community Perks</strong> : GoFloaters has partnered
                  with over 65+ companies to bring you over Rs 1 Crore of
                  benefits to you.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="padding60 brandBackground">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                    lineHeight: "1.3",
                    color: "#fff",
                  }}
                >
                  GoFloaters Advantage
                </h2>
              </div>
              <div className="container roundWhiteBackground">
                <div className="row">
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/pay-you-go.svg"
                        alt="Pay as you use"
                      ></img>
                      <p>Pay as you use</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/largest-network.svg"
                        alt="Largest Network"
                      ></img>{" "}
                      <p>Largest Network</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/affordable.svg"
                        alt="Affordable"
                      ></img>{" "}
                      <p>Affordable</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/work-near-home.svg"
                        alt="Work near home"
                      ></img>{" "}
                      <p>Work near home</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/no-contract.svg"
                        alt="No contracts"
                      ></img>{" "}
                      <p>No contracts</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="gofloaters-advantage alignerRight">
                      <img
                        src="https://assets.gofloaters.com/coworking/partner-benefits.svg"
                        alt="Partner benefits"
                      ></img>{" "}
                      <p>Partner benefits</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="TeamHomeBanner">
          <div className="container">
            <div className="row teamPadding">
              <div className="col-md-4 TeamHomeBannerleft">
                <h2 style={{ fontWeight: "bold", lineHeight: "1.3" }}>
                  Introducing<br></br>GoFloaters for Teams
                </h2>
                <br></br>
                <img src={teamLogo} width="250"></img>
                <br></br> <br></br>
                <p>
                  Save more than 60% from your current office rental contract by
                  offering pay-per-use office space for your remote team.
                </p>
                <Link to="/teams/" className="searchWorkspace ">
                  Show me how
                </Link>
                <br></br>
                <br></br>
              </div>
              <div className="col-md-6">
                <div className="GoflaoterHomeRight">
                  <div className="row">
                    <div className="col-md-8">
                      <h4>SIGN UP</h4>
                      <p>
                        Sign up on our platform within 3 minutes. No credit card
                        required.
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-8">
                      <h4>CHOOSE WORKSPACES</h4>
                      <p>
                        Choose your workspaces among 3000+ high quality
                        workspaces across the country.
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-8">
                      <h4>PAY-PER USE</h4>
                      <p>
                        Set your monthly budget and pay only when your employees
                        use our spaces. No contracts or upfront payments
                        involved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 introImage">
                <div className="animation-container" ref={animationContainer} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center" style={{ padding: "30px 0px" }}>
          <h1 style={{ color: "#000", fontWeight: "bold" }}>
            Covid-19 Safety Measures for Office Spaces
          </h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
              <p>
                GoFloaters cares about your well being and wants to ensure that
                you feel safe while working out of our spaces. That’s why we’ve
                developed the Safety Badge program along with our partner
                spaces.
              </p>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
              <br></br>
              <p className="text-center">
                The program is a voluntary pledge that our spaces take to ensure
                that their space meets our requirements for health and safety
                standards.
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div
            className="row"
            style={{
              border: "1px solid #d2dcf0",
              padding: "15px 20px",
              margin: "50px",
              borderRadius: "8px",
            }}
          >
            <div className="col-md-12 text-center">
              <h3>Safety Badge Requirements</h3>
              <p style={{ color: "#303030" }}>
                Required for Verified COVID Safety Badge
              </p>
              <br></br>
            </div>
            <div className="col-md-12 covid-response MobileOnly">
              <ul>
                <li>Mask Required</li>
                <li>Hand Sanitizer Provided</li>
                <li>Social Distancing between Workstations</li>
                <li>Cleaning and disinfection of Common Surfaces</li>
                <li>Safe Food Preparation and Pantry Services</li>
                <li>Visitor Records for Contact Tracing</li>
              </ul>
            </div>
            <div className="col-md-6 covid-response DesktopOnly">
              <ul>
                <li>Mask Required</li>
                <li>Hand Sanitizer Provided</li>
                <li>Social Distancing between Workstations</li>
              </ul>
            </div>
            <div className="col-md-6 covid-response DesktopOnly">
              <ul>
                <li>Cleaning and disinfection of Common Surfaces</li>
                <li>Safe Food Preparation and Pantry Services</li>
                <li>Visitor Records for Contact Tracing</li>
              </ul>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "15px 20px",
              margin: "40px",
            }}
          >
            <div className="col-md-6">
              <br></br>
              <h3>Identifying Safe Spaces</h3>
              <p>
                GoFloaters Safe Space badge will appear next to spaces that have
                verified safety measures undertaken and have met our safety
                standards.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img
                src={SafeSpace}
                style={{ width: "200px" }}
                alt="GoFloaters Verified Safe"
              ></img>
            </div>
          </div>
          <div
            className="row"
            style={{
              padding: "15px 20px",
              margin: "40px",
            }}
          ></div>
        </div> */}
        {/* <div className="" id="unlockDeals">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Unlock exclusive deals when you sign up</h2>
                <p>
                  We offer ₹1.5 Crores in free benefits and discounts for
                  eligible startups* through our partner program
                </p>
              </div>
            </div>
            <div className="row MobileOnly">
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
            </div>
            <div className="row gofloaterHomePartner DesktopOnly">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <div className="column1 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
                <div className="column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
                <div className="column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column5 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>&nbsp;</p>
                <Link
                  to="/our-community-perks/"
                  title="Our Community Perks"
                  style={{ color: "#303030", fontWeight: "bold" }}
                >
                  ...and many more
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="padding-60">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="container">
                  <div className="row ">
                    <div className="col-md-12 nocolpadding">
                      <p>Trusted by 10,000+ customers including</p>
                    </div>
                    <br></br>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding noLeftPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/decalthaonlogo.svg"
                        alt="Decalthaon"
                        className="lazyload"
                        width="159"
                        height="40"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/indusind.png"
                        alt="Indusind"
                        className="lazyload"
                        width="154"
                        height="18"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/nippon.png"
                        alt="Nippon"
                        className="lazyload"
                        width="154"
                        height="44"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/homelane.png"
                        alt="Homelane"
                        className="lazyload"
                        width="159"
                        height="38"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/clients/shriram.png"
                        alt="Shriram"
                        className="lazyload"
                        width="154"
                        height="46"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/clients/notion-press.png"
                        alt="Notion Press"
                        className="lazyload"
                        width="154"
                        height="34"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/mind-mom.png"
                        alt="Fusion"
                        className="lazyload"
                        width="154"
                        height="42"
                      ></img>
                    </div>
                    <div className="col-md-3 col-4 text-center img-hteams fivePixelPadding">
                      <img
                        data-src="https://assets.gofloaters.com/teams/think-music.jpg"
                        alt="Think Music"
                        className="lazyload"
                        width="154"
                        height="64"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8"></div>
            </div>
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}
